import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBaseUrl, getProjectId } from '../../utils';

import Flow from './Flow';

const FlowPage = () => {
	const navigate = useNavigate();

	const handleSuccess = useCallback(() => {
		navigate('/');
	}, [navigate]);

	return (
		<Flow
			projectId={getProjectId()}
			baseUrl={getBaseUrl()}
			onSuccess={handleSuccess}
		/>
	);
};

export default FlowPage;
