import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBaseUrl, getWizardProjectId } from '../../utils';
import Flow from './Flow';

const WizardFlowPage = () => {
	const navigate = useNavigate();

	const handleSuccess = useCallback(() => {
		navigate('/wizard');
	}, [navigate]);
	return (
		<Flow
			projectId={getWizardProjectId()}
			baseUrl={getBaseUrl()}
			onSuccess={handleSuccess}
		/>
	);
};

export default WizardFlowPage;
