import { useEffect } from 'react';
import { handleSessionResponse, serverHost } from '../utils';

const verify = async () => {
	const params = new URLSearchParams(window.location.search);
	const resp = await fetch(`${serverHost}/api/magiclink/verify`, {
		method: 'POST',
		headers: {
			'content-type': 'application/json'
		},
		body: JSON.stringify({
			token: params.get('t')
		})
	});
	const res = await resp.text();
	handleSessionResponse(res);
	return false;
};

const MagicLinkVerification = () => {
	useEffect(() => {
		verify().then(() => {
			window.location.href = window.location.origin;
		});
	}, []);
	return null;
};

export default MagicLinkVerification;
