import { serverHost } from './utils';

export const magicLink = async (email: string) => {
	const url = `${serverHost}/api/magiclink/signin-up`;
	await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json;charset=UTF-8'
		},
		body: JSON.stringify({
			email,
			redirectUrl: `${window.location.origin}/magiclink/verify`
		})
	});
};
