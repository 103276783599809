import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import './App.css';
import FlowPage from './components/Flow/FlowPage';
import WizardFlowPage from './components/Flow/WizardFlowPage';
import FlowsList from './components/FlowsList';
import MagicLink from './components/MagicLink';
import MagicLinkSent from './components/MagicLinkVerification';
import OAuthExchange from './components/OAuthExchange';
import OidcHosted from './components/Oidc/OidcPage';
import VerifySession from './components/VerifySession';
import WizardPage from './components/Wizard/WizardPage';
import { magicLink } from './magiclink';
import { oauthProviders, oauth } from './oauth';
import UserMgmtWidget from './components/UserManagementWidget';
import RoleManagementWidget from './components/RoleManagementWidget';
import AuditManagementWidget from './components/AuditManagementWidget';
import AccessKeyManagementWidget from './components/AccessKeyManagementWidget';
import UserAccessKeyManagementWidget from './components/UserAccessKeyManagementWidget';
import UserProfileWidget from './components/UserProfileWidget';

const Title = ({ label }: { label: string }) => (
	<span style={{ fontSize: '18px', marginTop: '25px', marginBottom: '5px' }}>
		{label}
	</span>
);

const Root = () => (
	<div className="App">
		<header className="App-header">
			<Title label="OAuth" />
			<div className="oauth-list">
				{Object.values(oauthProviders).map((provider) => (
					<button
						type="button"
						key={provider.key}
						onClick={() => {
							oauth(provider.key);
						}}
					>
						{provider.name}
					</button>
				))}
			</div>

			<Title label="Magic Link" />
			<MagicLink
				onClick={(email: string) => {
					magicLink(email);
					alert('Magic link sent'); // eslint-disable-line
				}}
			/>

			<Title label="Flows" />
			<FlowsList />

			<Title label="Widgets" />
			<div className="widget-list">
				<Link
					id="userWidget"
					to="/user-management-widget"
					style={{ margin: '6px' }}
				>
					{' '}
					User Widget{' '}
				</Link>
				<Link
					id="roleWidget"
					to="/role-management-widget"
					style={{ margin: '6px' }}
				>
					{' '}
					Role Widget{' '}
				</Link>
				<Link
					id="accessKeyWidget"
					to="/access-key-management-widget"
					style={{ margin: '6px' }}
				>
					{' '}
					Access Key Widget{' '}
				</Link>
				<Link
					id="userAccessKeyWidget"
					to="/user-access-key-management-widget"
					style={{ margin: '6px' }}
				>
					{' '}
					User Access Key Widget{' '}
				</Link>
				<Link
					id="auditWidget"
					to="/audit-management-widget"
					style={{ margin: '6px' }}
				>
					{' '}
					Audit Widget{' '}
				</Link>
				<Link
					id="userProfileWidget"
					to="/user-profile-widget"
					style={{ margin: '6px' }}
				>
					{' '}
					User Profile Widget{' '}
				</Link>
			</div>
			<Title label="More" />
			<div className="more-list">
				<Link id="wizard" to="/wizard" style={{ margin: '6px' }}>
					{' '}
					Wizard{' '}
				</Link>
				<Link id="oidc" to="/oidc" style={{ margin: '6px' }}>
					{' '}
					Oidc{' '}
				</Link>
			</div>
			<Title label="Verify" />
			<VerifySession />
		</header>
	</div>
);

const App = () => (
	<Routes>
		<Route path="/oidc" element={<OidcHosted />} />
		<Route path="/oauth" element={<OAuthExchange />} />
		<Route path="/magiclink/verify" element={<MagicLinkSent />} />
		<Route path="/success" element={<div>Yay</div>} />
		<Route path="/flow" element={<FlowPage />} />
		<Route path="/wizard" element={<WizardPage />} />
		<Route path="/wizard/flow" element={<WizardFlowPage />} />
		<Route path="/user-management-widget" element={<UserMgmtWidget />} />
		<Route path="/role-management-widget" element={<RoleManagementWidget />} />
		<Route
			path="/audit-management-widget"
			element={<AuditManagementWidget />}
		/>
		<Route path="/user-profile-widget" element={<UserProfileWidget />} />
		<Route
			path="/access-key-management-widget"
			element={<AccessKeyManagementWidget />}
		/>
		<Route
			path="/user-access-key-management-widget"
			element={<UserAccessKeyManagementWidget />}
		/>
		<Route path="/password/success" element={<div>Password SignUp OK</div>} />
		<Route path="/" element={<Root />} />
	</Routes>
);

export default App;
