import { AuthProvider, UserProfile } from '@descope/react-sdk';
import { getBaseUrl, getProjectId } from '../utils';

const UserProfileWidget = () => {
	return (
		<AuthProvider projectId={getProjectId()} baseUrl={getBaseUrl()}>
			<div
				style={{
					height: '100vh',
					position: 'relative',
					padding: '2em',
					backgroundImage:
						'linear-gradient(rgb(79,180,184), rgb(195, 143, 135))'
				}}
			>
				<UserProfile widgetId="user-profile-widget" />
			</div>
		</AuthProvider>
	);
};

export default UserProfileWidget;
