import {
	getAdminWidgetTenantIdFromQueryParams,
	getBaseUrl,
	getProjectId
} from '../utils';
import { AccessKeyManagement, AuthProvider } from '@descope/react-sdk';

const AccessKeyManagementWidget = () => {
	return (
		<AuthProvider projectId={getProjectId()} baseUrl={getBaseUrl()}>
			<div
				style={{
					height: '100vh',
					position: 'relative',
					padding: '2em',
					backgroundImage:
						'linear-gradient(rgb(79,180,184), rgb(195, 143, 135))'
				}}
			>
				<AccessKeyManagement
					widgetId="access-key-management-widget"
					tenant={getAdminWidgetTenantIdFromQueryParams()}
				/>
			</div>
		</AuthProvider>
	);
};

export default AccessKeyManagementWidget;
