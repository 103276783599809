import { SigninResponse } from 'oidc-client-ts';

const SESSION_KEY = 'sessionJwt';
const REFRESH_KEY = 'refreshJwt';

export const FLOW_IDS = [
	{
		id: 'sign-up-or-in',
		label: 'Sign Up Or In',
		locales: ['', 'es']
	},
	{
		id: 'sign-up',
		label: 'Sign Up',
		locales: ['']
	},
	{
		id: 'sign-up-auto-submit',
		label: 'Sign Up Auto Submit',
		locales: ['']
	},
	{
		id: 'step-up',
		label: 'Step Up',
		locales: ['']
	},
	{
		id: 'fingerprint-test',
		label: 'Fingerprint',
		locales: ['']
	}
];

export const FLOW_IDS_WIZARD = [
	{
		id: 'sign-up-or-in',
		label: 'Sign Up Or In',
		locales: ['']
	},
	{
		id: 'sign-in',
		label: 'Sign In',
		locales: ['']
	},
	{
		id: 'sign-up',
		label: 'Sign Up',
		locales: ['']
	},
	{
		id: 'step-up',
		label: 'Step Up',
		locales: ['']
	}
];

export const handleSessionResponse = (res: string) => {
	const session = res.split('|');
	localStorage.setItem(SESSION_KEY, session[0]);
	localStorage.setItem(REFRESH_KEY, session[1]);
};

export const handleSessionFlowResponse = (e: CustomEvent) => {
	localStorage.setItem(SESSION_KEY, e.detail?.sessionJwt);
	localStorage.setItem(REFRESH_KEY, e.detail?.refreshJwt);
};

export const handleSessionOidcResponse = (res: SigninResponse) => {
	localStorage.setItem(SESSION_KEY, res?.access_token || '');
	localStorage.setItem(REFRESH_KEY, res?.refresh_token || '');
};

export const getSessionJwt = () => localStorage.getItem(SESSION_KEY);
export const getRefreshJwt = () => localStorage.getItem(REFRESH_KEY);

export const serverHost =
	process.env.REACT_APP_SERVER_HOST || window.location.origin;

export const setContentURL = () => {
	const baseUrl = process.env.REACT_APP_CONTENT_BASE_URL as string;
	if (baseUrl) {
		localStorage.setItem('base.content.url', baseUrl);
	}
};

export const getProjectId = () =>
	process.env.REACT_APP_DESCOPE_PROJECT_ID as string;

export const getBaseUrl = () =>
	process.env.REACT_APP_DESCOPE_BASE_URL as string;

export const getWizardProjectId = () =>
	process.env.REACT_APP_WIZARD_DESCOPE_PROJECT_ID as string;

export const getAdminWidgetTenantIdFromQueryParams = () => {
	return (new URLSearchParams(window.location.search).get('tenantId') ??
		process.env.REACT_APP_SSO_TENANT_ID) as string;
};
