import { serverHost } from './utils';

export const oauthProviders = {
	google: { key: 'google', name: 'Google' },
	github: { key: 'github', name: 'GitHub' },
	microsoft: { key: 'microsoft', name: 'Microsoft' },
	apple: { key: 'apple', name: 'Apple' },
	facebook: { key: 'facebook', name: 'Facebook' },
	discord: { key: 'discord', name: 'Discord' },
	linkedin: { key: 'linkedin', name: 'LinkedIn' },
	slack: { key: 'slack', name: 'Slack' }
};

export const oauth = async (provider: string) => {
	const resp = await fetch(`${serverHost}/api/oauth`, {
		method: 'POST',
		headers: {
			'content-type': 'application/json;charset=UTF-8'
		},
		body: JSON.stringify({
			provider,
			redirectUrl: `${window.location.origin}/oauth`
		})
	});
	try {
		const redirect = await resp.text();
		window.location.href = redirect;
	} catch (e) {
		console.log(e);
	}
};
