import VerifySession from '../VerifySession';
import './OidcPage.css';
import { OidcClient, OidcClientSettings, SigninResponse } from 'oidc-client-ts';
import React, { useEffect, useRef, Suspense, useState } from 'react';
import {
	handleSessionOidcResponse,
	getBaseUrl,
	getProjectId
} from '../../utils';

const Oidc = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const projectId = getProjectId();
	const authority = new URL(projectId, getBaseUrl()).href;
	const oidcSettings = useRef<OidcClientSettings>({
		authority: authority,
		client_id: projectId,
		scope: 'openid email',
		redirect_uri: window.location.href, // Where to redirect after login
		response_type: 'code'
	});

	// Create OIDC client
	const client = useRef<OidcClient>(new OidcClient(oidcSettings.current));

	// Log errors
	const err = async (err: Error) => {
		console.error(err);
	};

	// Create signin request
	const authenticate = () => {
		client.current
			.createSigninRequest({})
			.then(({ url, state }) => {
				console.log(state);
				window.location.href = url;
			})
			.catch(err);
	};

	// Process signin response
	const processSigninResponse = () => {
		client.current
			.processSigninResponse(window.location.href)
			.then(_finishLogin)
			.catch(err);
	};

	// handle signin response
	const _finishLogin = (res: SigninResponse) => {
		handleSessionOidcResponse(res);
	};

	useEffect(() => {
		if (urlParams.get('code') && urlParams.get('state')) {
			processSigninResponse();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Suspense>
			<>
				<div className="space-bg" />
				<div className="oidc-page">
					<span className="section-header">OIDC Flow with Hosted Page</span>
					<button
						type="button"
						id="oidc-flow"
						onClick={() => {
							authenticate();
						}}
					>
						Sign In
					</button>
					<span className="section-header">Verify</span>
					<VerifySession />
				</div>
			</>
		</Suspense>
	);
};

export default Oidc;
