import React, { lazy, Suspense } from 'react';
import { getBaseUrl, getWizardProjectId } from '../../utils';
import FlowsList from '../FlowsList';
import VerifySession from '../VerifySession';
import './WizardPage.css';

// we load descope component in lazy manner (i.e. only when element is rendered on the screen for the first time)
// we do this because the sdk code uses localStorage on init, and we want to make sure we set it before this component is loaded
const AuthProvider = lazy(() =>
	import('@descope-int/react-dynamic-sdk').then((module) => ({
		default: module.AuthProvider
	}))
);

const Wizard = () => (
	<Suspense>
		<AuthProvider
			projectId={getWizardProjectId() || ''}
			baseUrl={getBaseUrl() || ''}
		>
			<>
				<div className="space-bg" />
				<div className="wizard-page">
					<span className="section-header">Wizard Flows</span>
					<FlowsList linkPrefix="/wizard/flow" wizard />
					<span className="section-header">Verify</span>
					<VerifySession wizard />
				</div>
			</>
		</AuthProvider>
	</Suspense>
);

export default Wizard;
