import React from 'react';
import { Link } from 'react-router-dom';
import { FLOW_IDS, FLOW_IDS_WIZARD } from '../utils';
import './FlowsList.css';

const FlowsList = (props: { linkPrefix?: string; wizard?: boolean }) => {
	const { linkPrefix, wizard } = props;
	return (
		<div className="flows-list">
			{(wizard ? FLOW_IDS_WIZARD : FLOW_IDS).map(({ id, label, locales }) => {
				return locales?.map((locale) => {
					return (
						<Link
							key={`${id}_${locale}`}
							id={locale ? `flow-${id}-${locale}` : `flow-${id}`}
							to={`${linkPrefix}?id=${id}&locale=${locale}`}
							style={{ margin: '6px' }}
						>
							{label}
							{locale ? ` (${locale})` : ''}
						</Link>
					);
				});
			})}
		</div>
	);
};

FlowsList.defaultProps = {
	linkPrefix: '/flow',
	wizard: false
};

export default FlowsList;
