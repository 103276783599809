import React, { useState } from 'react';

const MagicLink = (props: { onClick: (email: string) => void }) => {
	const [email, setEmail] = useState('');

	const handleEmailChange = (event: any) => {
		setEmail(event.target.value);
	};

	const handleSubmit = (event: any) => {
		const { onClick } = props;
		onClick(email);
		event.preventDefault();
	};

	return (
		<form
			onSubmit={handleSubmit}
			style={{
				fontSize: '14px',
				display: 'flex',
				flexDirection: 'row'
			}}
		>
			<input
				type="email"
				placeholder="email"
				id="email"
				value={email}
				onChange={handleEmailChange}
			/>
			<input type="submit" id="submit" value="Magic Link" />
		</form>
	);
};

export default MagicLink;
